<template>
  <div class="current-program-empty">
    <div class="current-program__title">
      {{ $t('index.headerTitle', { name: member.name }) }}
    </div>

    <p
      class="current-program__paragraph"
      v-html-safe="$t('index.headerBody')"
    />

    <base-button
      modifiers="tertiary"
      class="current-program__button"
      v-on:click="$router.push({ name: 'programs' })"
    >
      {{ $t('index.headerButtonLabel') }}
    </base-button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseButton from '@/components/BaseButton';

export default {
  components: {
    BaseButton,
  },

  computed: {
    ...mapState('member', ['member']),
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.current-program-empty {
  background: $color-white url("~@/assets/overview/overview-empty-mobile.jpg") no-repeat right top;
  background-size: contain;
  border-radius: $border-radius;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  min-height: 15rem;
  padding: $spacing-md;

  @media (min-width: $breakpoint-md) {
    background: $color-white url("~@/assets/overview/overview-empty-desktop.jpg") no-repeat right top;
    background-size: contain;
  }

  .current-program__title {
    @include heading-5;
    margin-bottom: $spacing-xs;
    max-width: 15rem;

    @media (min-width: $breakpoint-md) {
      max-width: 21rem;
    }
  }

  .current-program__paragraph {
    @include note;
  }

  .current-program__button {
    align-self: flex-start;
    margin-top: auto;
  }
}
</style>
