<template>
  <div v-bind:class="{
    'overviewCurrentProgram': true,
    'overviewCurrentProgram--loading': areProgramsLoading,
    'overviewCurrentProgram--noPrograms': !programs.length,
  }">
    <template v-if="!areProgramsLoading">
      <carousel
        v-if="programs.length"
        v-bind:items-to-show="1.05"
        v-bind:wrap-around="true"
      >
        <slide
          v-for="program in programs"
          v-bind:key="program.name"
        >
          <current-program v-bind:program="program" />
        </slide>

        <template v-slot:addons>
          <pagination />
          <navigation />
        </template>
      </carousel>

      <current-program-empty v-if="programs.length === 0" />
    </template>

    <template v-else>
      <skeleton-loader-card
        v-bind:show-title="false"
        image-height="291px"
        class="overviewCurrentProgram__loader"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import CurrentProgram from './CurrentProgram';
import CurrentProgramEmpty from './CurrentProgramEmpty';
import SkeletonLoaderCard from '@/components/SkeletonLoaderCard';

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    SkeletonLoaderCard,
    CurrentProgram,
    CurrentProgramEmpty,
  },

  props: {
    programs: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapState('program', ['areProgramsLoading']),
  },
};
</script>

<style lang="scss">
@import "@/scss/lib";

.carousel__prev,
.carousel__next {
  box-shadow: 1px 1px 5px rgba($color-black, .15);

  @include mobile {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
@import "@/scss/lib";

.overviewCurrentProgram {
  &--loading,
  &--noPrograms {
    padding: 0 #{$gridGutterWidth / 2};
  }
}

.overviewCurrentProgram__loader {
  --skeleton-loader-bg-color: #ffffff;
  --skeleton-loader-bg-color-mid: #f3f6f9;
}

.carousel {
  --vc-clr-primary: #{$color-yellow--dark};
  --vc-clr-secondary: #{$color-beige--dark};

  --vc-pgn-width: 8px;
  --vc-pgn-height: 8px;
  --vc-pgn-border-radius: 50%;
  --vc-pgn-background-color: var(--vc-clr-secondary);
  --vc-pgn-active-color: var(--vc-clr-primary);

  --vc-nav-width: 48px;
  --vc-nav-height: 48px;
  --vc-nav-border-radius: 50%;
  --vc-nav-color: #{$color-green};
  --vc-nav-color-hover: #{$color-green--dark};
  --vc-nav-background: #{$color-white};
}

.carousel__slide {
  padding: 5px;
}
</style>
