<template>
  <div class="progressSummaryItem">
    <div class="progressSummaryItem__icon">
      <slot />
    </div>

    <div class="progressSummaryItem__value">
      {{ value }}
    </div>

    <div class="progressSummaryItem__unit">
      {{ unit }}
    </div>

    <div
      v-if="lastValue"
      class="progressSummaryItem__lastMonth"
    >
      {{ $t('index.progressSummaryLastMonthValue', { value: lastValue }) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },

    unit: {
      type: String,
      required: true,
    },

    lastValue: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.progressSummaryItem {
  display: flex;
  align-items: center;
}

.progressSummaryItem__icon {
  margin: 0 rem(10px) 0 0;
  font-size: 16px;
  color: $color-yellow;
}

.progressSummaryItem__value {
  @include heading-4;
  margin: 0 rem(4px) 0 0;
  color: $color-text;
}

.progressSummaryItem__unit {
  @include label;
  color: $color-text;
}

.progressSummaryItem__lastMonth {
  display: none;

  @include desktop {
    @include tiny;
    display: block;
    margin: 0 0 0 rem(4px);
    color: $color-grey--medium;
  }
}
</style>