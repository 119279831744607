<template>
  <div>
    <base-section v-bind:style="`background-color: ${firstSectionBackgroundColor};`">
      <medical-consent-banner v-if="member.medical_consent === null" />

      <overview-header v-bind:programs="activePrograms" />
    </base-section>

    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <overview-blogs />
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <overview-programs v-bind:programs="inactivePrograms" />
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <overview-recipes />
          </base-column>
        </base-row>
      </base-grid>
    </base-section>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import BaseSection from '@/components/grid/BaseSection';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import OverviewHeader from '@/components/overview/OverviewHeader';
import OverviewBlogs from '@/components/overview/OverviewBlogs';
import OverviewPrograms from '@/components/overview/OverviewPrograms';
import OverviewRecipes from '@/components/overview/OverviewRecipes';
import MedicalConsentBanner from '@/components/MedicalConsentBanner';
import colors from '@/scss/_colors.scss';

export default {
  components: {
    BaseSection,
    BaseGrid,
    BaseRow,
    BaseColumn,
    OverviewHeader,
    OverviewBlogs,
    OverviewPrograms,
    OverviewRecipes,
    MedicalConsentBanner,
  },

  data() {
    return {
      firstSectionBackgroundColor: colors.color_background_beige,
    };
  },

  computed: {
    ...mapState('member', ['member']),
    ...mapGetters('program', ['activePrograms', 'inactivePrograms']),
  },

  created() {
    this.$store.dispatch('program/fetchAll');
  },
};
</script>
