<template>
  <div class="currentProgram">
    <router-link
      v-bind:to="programLink"
      class="currentProgram__image"
      v-bind:style="{ backgroundImage: `url(${program.image_mobile}?w=1000&q=80)` }"
    />

    <div class="currentProgram__content">
      <div class="currentProgram__titleWrapper">
        <div class="currentProgram__title">
          <router-link v-bind:to="programLink">
            {{ program.name }}
          </router-link>
        </div>

        <small
            v-if="program.trainers[0]"
        >
          {{ $t('index.headerProgramWithTrainer', { trainer: program.trainers[0].name }) }}
        </small>
      </div>

      <p
        v-if="program.description"
        v-html-safe="program.description"
        class="currentProgram__paragraph"
      />

      <p class="currentProgram__period">
        {{
          $t('index.headerProgramPeriodXofY', {
            x: program.periods.findIndex(({ id }) => id === program.active_period) + 1,
            y: program.periods.length
          })
        }}

        <template v-if="program.active_workout">
          - {{ program.active_workout.name }}
        </template>

        <Progressbar
          v-bind:percentage="progressPercentage"
          class="currentProgram__progressbar"
        />
      </p>

      <div class="currentProgram__buttons">
        <base-button
          v-bind:to="programLink"
          modifiers="primary"
        >
          <span class="currentProgram__buttonLabel currentProgram__buttonLabel--mobile">
            {{ programButtonLabelMobile }}
          </span>

          <span class="currentProgram__buttonLabel currentProgram__buttonLabel--desktop">
            {{ programButtonLabel }}
          </span>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton';
import BaseHeading from '@/components/BaseHeading';
import Progressbar from '@/components/Progressbar';

export default {
  components: {
    BaseButton,
    BaseHeading,
    Progressbar,
  },

  props: {
    program: {
      type: Object,
      required: true,
    }
  },

  computed: {
    progressPercentage() {
      const numPeriods = this.program.periods.length;
      const activePeriodIndex = this.program.periods.findIndex(({ id }) => {
        return id === this.program.active_period;
      });

      return (100 / numPeriods) * (activePeriodIndex + 1);
    },

    programButtonLabelMobile() {
      if (this.program.active_workout) {
        return this.$t('index.headerProgramButtonLabelMobile');
      }

      return this.$t('index.headerProgramCompletedButtonLabelMobile');
    },

    programButtonLabel() {
      if (this.program.active_workout) {
        return this.$t('index.headerProgramButtonLabel');
      }

      return this.$t('index.headerProgramCompletedButtonLabel');
    },

    programLink() {
      if (this.program.active_workout) {
        return {
          name: 'programWorkout',
          params: {
            programId: this.program.id,
            periodId: this.program.active_period,
          },
        };
      }

      return {
        name: 'program',
        params: {
          programId: this.program.id,
          periodId: this.program.active_period,
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/lib";

.currentProgram__paragraph {
  &,
  p {
    display: none;

    @media(min-width: 1024px) {
      @include label;
      margin: 0 0 1rem 0;
      display: block;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/scss/lib";

$currentProgram-content-padding: 1.5rem;

.currentProgram {
  border-radius: 10px;
  overflow: hidden;
  width: 100%;

  @include desktop {
    display: flex;
  }
}

.currentProgram__image {
  display: block;
  position: relative;
  height: rem(160px);
  background-size: cover;

  @include desktop {
    flex: 0 0 50%;
    min-height: 15rem;
    height: auto;
  }
}

.currentProgram__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: $color-white;

  @include desktop {
    align-items: flex-start;
    flex-direction: column;
    flex: 0 0 50%;
    position: relative;
    padding: 1.5rem;
  }
}

.currentProgram__titleWrapper {
  margin: 0 0 rem(6px) 0;
  text-align: left;
}

.currentProgram__title {
  @include heading-5;

  @include desktop {
    @include heading-3;
    max-width: 15rem;
    color: $color-black;
  }
}

.currentProgram__paragraph {
  text-align: left;
}

small {
  display: none;

  @include desktop {
    @include small;
    display: block;
    margin-top: 0.125rem;
    color: inherit;
  }
}

.currentProgram__period {
  display: none;

  @media(min-width: 768px) {
    @include note;
    display: block;
    position: relative;
    margin: 30px 0;
    text-align: left;
  }

  @media(min-width: 1024px) {
    position: absolute;
    top: $currentProgram-content-padding;
    right: $currentProgram-content-padding;
    margin: 0;
    width: 12rem;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 24px;
      background-image: linear-gradient(to right, rgba($color-white, 0), $color-white);
    }
  }
}

.currentProgram__progressbar {
  margin: 0.75rem 0;
  width: 100%;
}

.currentProgram__buttons {
  flex: 0 0 auto;
}

.currentProgram__buttonLabel {
  &--mobile {
    @include desktop {
      display: none;
    }
  }

  &--desktop {
    @include mobile {
      display: none;
    }
  }
}
</style>
