<template>
  <component
    v-bind:is="componentType"
    v-bind="componentProps"
    v-bind:class="{
      myScheduleItem: true,
      'myScheduleItem--today': isToday,
      'myScheduleItem--hasWorkout': hasScheduledWorkout,
      'myScheduleItem--workoutHasRating': workoutHasRating,
      [`myScheduleItem--workoutRating${workoutRating}`]: workoutHasRating,
    }"
  >
    <div
      v-if="!workoutHasRating"
      class="myScheduleItem__date"
    >
      {{ new Date(data.date).getDate() }}
    </div>

    <img
      v-if="workoutHasRating"
      v-bind:src="require(`@/assets/rating/rating-${data.workout.rating}.png`)"
      class="myScheduleItem__ratingIcon"
    />

    <div class="myScheduleItem__day">
      {{ new Date(data.date).toLocaleString('nl-NL', { weekday: 'short' }) }}
    </div>
  </component>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isClickable() {
      const today = moment().startOf('day');
      const isBeforeToday = moment(this.data.date).isBefore(moment(today));

      return this.hasScheduledWorkout && this.hasScheduledProgram && !isBeforeToday;
    },

    componentType() {
      if (this.isClickable) {
        return 'router-link';
      }

      return 'div';
    },

    componentProps() {
      if (!this.isClickable) {
        return {};
      }

      const { id, active_period } = this.data.program;

      return {
        to: {
          name: 'programWorkout',
          params: {
            programId: id,
            periodId: active_period,
          },
        },
      };
    },

    isToday() {
      return moment(this.data.date).isSame(moment(), 'day');
    },

    hasScheduledWorkout() {
      return !!this.data.program;
    },

    hasScheduledProgram() {
      return this.hasScheduledWorkout && !!this.data.program.id;
    },

    workoutHasRating() {
      return this.data.workout && this.data.workout.rating;
    },

    workoutRating() {
      if (this.workoutHasRating) {
        return this.data.workout.rating;
      }

      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.myScheduleItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 4rem;
  background: $color-white;
  border-radius: 10px;
  box-shadow: 0 0.125rem 0.3751rem 0 rgba(#000, 0.06);

  &--today {
    background: $color-beige--dark;
  }

  &--workoutHasRating {
    color: $color-white;
  }

  @for $i from 1 through 5 {
    &--workoutRating#{$i} {
      background: map-get($rating-colors, $i);
    }
  }

  &--hasWorkout {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 6px;
      right: 6px;
      width: 6px;
      height: 6px;
      background: $color-yellow;
      border-radius: 50%;
    }

    &.myScheduleItem--today,
    &.myScheduleItem--workoutHasRating {
      &:after {
        background: $color-white;
      }
    }
  }
}

.myScheduleItem__date {
  @include paragraph--bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25px;
}

.myScheduleItem__ratingIcon {
  width: 25px;
  height: 25px;
}

.myScheduleItem__day {
  @include paragraph;
}
</style>
