<template>
  <div class="overviewBlogs">
    <base-heading type="4">
      {{ $t('index.blogsTitle') }}
    </base-heading>

    <mobile-slider>
      <template v-if="!blogItems.length">
        <mobile-slider-item
          v-for="n in numBlogItems"
          v-bind:key="n"
          mobile-width="75%"
          desktop-width="33.3%"
        >
          <skeleton-loader-card />
        </mobile-slider-item>
      </template>

      <template v-else>
        <mobile-slider-item
          v-for="(blog, index) in blogItems.slice(0, numBlogItems)"
          v-bind:key="index"
          mobile-width="75%"
          desktop-width="33.3%"
        >
          <blog-card v-bind:blog="blog" />
        </mobile-slider-item>
      </template>
    </mobile-slider>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MobileSlider from '@/components/MobileSlider';
import MobileSliderItem from '@/components/MobileSliderItem';
import SkeletonLoaderCard from '@/components/SkeletonLoaderCard';
import BaseHeading from '@/components/BaseHeading';
import BlogCard from '@/components/BlogCard';

export default {
  components: {
    MobileSlider,
    MobileSliderItem,
    SkeletonLoaderCard,
    BaseHeading,
    BlogCard,
  },

  data() {
    return {
      numBlogItems: 3,
    };
  },

  computed: {
    ...mapState('blog', ['blogItems']),
  },

  created() {
    this.getBlogs();
  },

  methods: {
    ...mapActions({
      getBlogs: 'blog/getBlogItems',
    }),
  },
};
</script>
