<template>
  <div class="overviewHeader">
    <base-grid modifiers="maxWidth">
      <base-row>
        <base-column>
          <page-title-bar class="overviewHeader__pageTitleBar">
            {{ $t('index.pageTitle') }}

            <template v-slot:extra>
              <div
                v-bind:class="{
                  'overviewHeader__notificationsToggleIconWrapper': true,
                  'overviewHeader__notificationsToggleIconWrapper--showCount': !!numNewNotifications,
                }"
                v-bind:data-count="numNewNotifications"
                v-on:click="onNotificationsToggleClick"
              >
                <font-awesome-icon
                  v-bind:icon="['fas', 'bell']"
                  class="overviewHeader__notificationsToggle"
                />
              </div>
            </template>
          </page-title-bar>

          <overview-current-program
            v-bind:programs="programs"
            class="overviewHeader__carousel"
            style="margin-left: -16px; margin-right: -16px;"
          />

          <div class="overviewHeader__info">
            <div class="overviewHeader__infoContainer">
              <my-schedule class="overviewHeader__schedule" />
            </div>

            <div class="overviewHeader__infoContainer">
              <progress-summary class="overviewHeader__progressSummary" />
            </div>

            <div
              v-if="latestBadge"
              class="overviewHeader__infoContainer overviewHeader__infoContainer--desktopOnly"
            >
              <div class="overviewHeader__latestBadge">
                <img
                  v-bind:src="latestBadge.image"
                  v-bind:alt="latestBadge.name"
                  class="overviewHeader__latestBadgeImage"
                />

                <div class="overviewHeader__latestBadgeContent">
                  <div class="overviewHeader__latestBadgeTitle">
                    {{ $t('index.latestBadgeTitle') }}
                  </div>

                  <div class="overviewHeader__latestBadgeName">
                    {{ latestBadge.name }}
                  </div>

                  <div
                    class="overviewHeader__latestBadgeShareButton"
                    v-on:click="onBadgeClick(latestBadge)"
                  >
                    <font-awesome-icon
                      icon="share-nodes"
                      class="overviewHeader__latestBadgeShareButtonIcon"
                    />

                    {{ $t('index.latestBadgeButtonLabel') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </base-column>
      </base-row>
    </base-grid>
  </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import { mapState, mapGetters } from 'vuex';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import BaseButton from '@/components/BaseButton';
import PageTitleBar from '@/components/PageTitleBar';
import OverviewCurrentProgram from '@/components/overview/OverviewCurrentProgram';
import MySchedule from '@/components/myschedule/MySchedule';
import ProgressSummary from '@/components/ProgressSummary';
import { ModalBus } from '@/eventBus';

const BadgeModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/badges/BadgeModal' /* webpackChunkName: "badgeModal" */),
}));

export default {
  components: {
    BaseGrid,
    BaseRow,
    BaseColumn,
    BaseButton,
    PageTitleBar,
    OverviewCurrentProgram,
    MySchedule,
    ProgressSummary,
  },

  props: {
    programs: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapState('badges', ['latestBadge']),
    ...mapGetters('notifications', ['numNewNotifications']),
    ...mapState('state', ['isNotificationsNavActive']),
  },

  methods: {
    onNotificationsToggleClick() {
      this.toggleNotificationsNav(!this.isNotificationsNavActive);
    },

    toggleNotificationsNav(toggle) {
      this.$store.commit('state/toggleNotificationsNav', toggle);
    },

    onBadgeClick(badge) {
      ModalBus.emit('open', {
        component: BadgeModal,
        modifiers: 'badge',
        props: { badge },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.overviewHeader {
  padding: 20px 0;
  background-color: $color-beige;

  @media (min-width: $breakpoint-md) {
    padding: 2.5rem 0;
  }
}

.overviewHeader__pageTitleBar {
  margin: 0 0 16px 0;
}

.overviewHeader__carousel {
  margin: 0 #{$gridGutterWidth / 2};
  margin-bottom: rem(24px);

  @include desktop {
    margin-bottom: rem(32px);
  }
}

.overviewHeader__notificationsToggleIconWrapper {
  position: relative;

  &--showCount {
    &:after {
      content: attr(data-count);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -5px;
      right: -5px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid $color-white;
      background: #c80000;
      font-size: 12px;
      font-weight: bold;
      color: $color-white;
    }
  }
}

.overviewHeader__notificationsToggle {
  font-size: 24px;
  color: $color-green;
}

.overviewHeader__schedule,
.overviewHeader__progressSummary,
.overviewHeader__latestBadge {
  height: 100%;
}

.overviewHeader__info {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.overviewHeader__infoContainer {
  flex: 0 0 calc(50% - #{rem(6px)});

  @include desktop {
    flex: 0 0 calc(33% - #{rem(15px)});
  }

  &--desktopOnly {
    display: none;

    @include desktop {
      display: block;
    }
  }
}

.overviewHeader__latestBadge {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  border-radius: 10px;
  background: $color-white;
}

.overviewHeader__latestBadgeImage {
  flex: 0 0 auto;
  margin: 0 rem(20px) 0 0;
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.overviewHeader__latestBadgeContent {
  flex: 1 1 auto;
}

.overviewHeader__latestBadgeTitle {
  @include note;
  margin: 0 0 rem(4px) 0;
  color: $color-text;
}

.overviewHeader__latestBadgeName {
  @include paragraph--bold;
  margin: 0 0 rem(4px) 0;
  color: $color-black;
}

.overviewHeader__latestBadgeShareButton {
  @include tiny--bold;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: $color-green;
  cursor: pointer;
}

.overviewHeader__latestBadgeShareButtonIcon {
  margin: 0 rem(6px) 0 0;
  font-size: 16px;
}
</style>
