<template>
  <div
    v-if="activity.current_month"
    class="progressSummary"
  >
    <div class="progressSummary__header">
      <div class="progressSummary__title">
        {{ $t('index.progressSummaryTitle') }}
      </div>

      <div
        modifiers="link"
        class="progressSummary__measurementsButton"
        v-on:click="onNewBodyMeasurementsClick"
      >
        <font-awesome-icon
          icon="plus"
          class="progressSummary__measurementsButton__icon"
        />

        {{ $t('index.progressSummaryMeasurementsButtonLabel') }}
      </div>
    </div>

    <router-link v-bind:to="{ name: 'aboutyouProgress' }">
      <progress-summary-item
        v-bind:value="Math.round(activity.current_month.workouts)"
        v-bind:unit="$tc('index.progressSummaryWorkouts', Math.round(activity.current_month.workouts))"
        v-bind:last-value="Math.round(activity.previous_month.workouts)"
        class="progressSummary__contentItem"
      >
        <font-awesome-icon icon="person-running" />
      </progress-summary-item>

      <progress-summary-item
        v-bind:value="Math.floor(activity.current_month.minutes)"
        v-bind:unit="$tc('index.progressSummaryMinutes', Math.floor(activity.current_month.minutes))"
        v-bind:last-value="Math.floor(activity.previous_month.minutes)"
        class="progressSummary__contentItem"
      >
        <font-awesome-icon icon="stopwatch" />
      </progress-summary-item>

      <progress-summary-item
        v-bind:value="Math.round(activity.current_month.calories)"
        v-bind:unit="$t('index.progressSummaryCalories')"
        v-bind:last-value="Math.round(activity.previous_month.calories)"
        class="progressSummary__contentItem"
      >
        <font-awesome-icon icon="fire" />
      </progress-summary-item>
    </router-link>
  </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import { mapActions, mapState } from 'vuex';
import ProgressSummaryItem from '@/components/ProgressSummaryItem';
import BaseButton from '@/components/BaseButton';
import { ModalBus } from '@/eventBus';

const BodyMeasurementsModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/aboutyou/body/BodyMeasurementsModal' /* webpackChunkName: "bodyMeasurementsModal" */),
}));

export default {
  components: {
    ProgressSummaryItem,
    BaseButton,
  },

  computed: {
    ...mapState('member', ['activity']),
  },

  created() {
    this.fetchActivity();
  },

  methods: {
    ...mapActions('member', ['fetchActivity']),

    onNewBodyMeasurementsClick() {
      ModalBus.emit('open', {
        component: BodyMeasurementsModal,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.progressSummary {
  padding: rem(16px);
  border-radius: rem(6px);
  background: $color-white;

  @include desktop {
    border-radius: rem(10px);
  }
}

.progressSummary__header {
  margin: 0 0 12px 0;

  @include desktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.progressSummary__title {
  @include note;
  text-align: center;
  color: $color-green;

  @include desktop {
    @include label;
    text-align: left;
    color: $color-text;
  }
}

.progressSummary__measurementsButton {
  @include label--bold;
  display: none;
  color: $color-green;
  cursor: pointer;

  @include desktop {
    display: block;
  }
}

.progressSummary__measurementsButton__icon {
  margin: 0 rem(6px) 0 0;
}

.progressSummary__contentItem {
  margin: 0 0 rem(3px) 0;

  @include desktop {
    margin: 0 0 rem(3px) rem(5px);
  }

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
