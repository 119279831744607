<template>
  <div class="overviewPrograms">
    <base-heading type="4">
      {{ $t('index.programsTitle') }}
    </base-heading>

    <mobile-slider>
      <template v-if="!programs.length">
        <mobile-slider-item
          v-for="n in numPrograms"
          v-bind:key="n"
          mobile-width="66%"
          desktop-width="25%"
        >
          <skeleton-loader-card />
        </mobile-slider-item>
      </template>

      <template v-else>
        <mobile-slider-item
          v-for="(program, index) in programs.slice(0, numPrograms)"
          v-bind:key="index"
          mobile-width="66%"
          desktop-width="25%"
        >
          <card-program v-bind:program="program" />
        </mobile-slider-item>
      </template>
    </mobile-slider>
  </div>
</template>

<script>
import MobileSlider from '@/components/MobileSlider';
import MobileSliderItem from '@/components/MobileSliderItem';
import SkeletonLoaderCard from '@/components/SkeletonLoaderCard';
import BaseHeading from '@/components/BaseHeading';
import CardProgram from '@/components/CardProgram';

export default {
  components: {
    MobileSlider,
    MobileSliderItem,
    SkeletonLoaderCard,
    BaseHeading,
    CardProgram,
  },

  props: {
    programs: {
      type: Array,
      required: true,
    }
  },

  data() {
    return {
      numPrograms: 4,
    };
  }
};
</script>
