<template>
  <a
    v-bind:href="blog.url"
    class="blogCard"
  >
    <div class="blogCard__image">
      <div class="blogCard__picture">
        <img
          v-bind:src="blog.featuredImage"
          v-bind:srcset="`${blog.featuredImage2x} 2x`"
        />
      </div>

      <font-awesome-icon
        class="blogCard__icon"
        icon="square-arrow-up-right"
      />
    </div>

    <div class="blogCard__category">
      {{ blog.categories[0] }}
    </div>

    <div class="blogCard__title">
      {{ blog.title }}
    </div>
  </a>
</template>

<script>
export default {
  props: {
    blog: {
      type: Object,
      required: true,
    },
  },

  methods: {
    getFeaturedImage(srcset) {
      if (srcset) {
        if (srcset.indexOf(',') !== -1) {
          return srcset.split(',')[1].split(' ')[1];
        }

        return srcset;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.blogCard__image {
  background-color: $color-beige;
  background-size: cover;
  background-position: center center;
  border-radius: $border-radius;
  height: $card-height;
  position: relative;
  width: 100%;

  @media (min-width: $breakpoint-md) {
    height: $card-height-desktop;
  }
}

.blogCard__picture {
  border-radius: $border-radius;
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.blogCard__icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: $color-white;
}

.blogCard__category {
  @include note;
  margin: 0.625rem 0 0 0;
  color: $color-grey;
}

.blogCard__title {
  @include small;
  margin: 0.1875rem 0 0 0;
}
</style>
