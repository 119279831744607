<template>
  <div
    v-bind:class="{
      'mySchedule': true,
      'mySchedule--hasSchedule': hasSchedule,
    }"
  >
    <div v-if="hasSchedule">
      <div class="mySchedule__header">
        <div class="mySchedule__title">
          {{ $t('mySchedule.title') }}
        </div>

        <div
          modifiers="link"
          class="mySchedule__editButton"
          v-on:click="onShowSettingsModal"
        >
          <font-awesome-icon
            icon="pen"
            class="mySchedule__editButtonIcon"
          />

          {{ $t('mySchedule.editButtonLabel') }}
        </div>
      </div>

      <div class="mySchedule__content">
        <div class="mySchedule__progress">
          <div class="mySchedule__progressText">
            <div>
              <span class="mySchedule__progressTextLarge">{{ numCompletedWorkouts }}</span> /{{ numTotalWorkouts }}
            </div>

            <canvas
              ref="canvas"
              width="80"
              height="80"
              class="mySchedule__progressCanvas"
            />
          </div>
        </div>

        <p class="mySchedule__body">
          {{ bodyText }}
        </p>
      </div>
    </div>

    <div
      v-if="!hasSchedule"
      class="mySchedule__noScheduleContainer"
      v-on:click="onShowSettingsModal"
    >
      <p class="mySchedule__noScheduleBody">
        {{ $t('mySchedule.startCreateButtonLabel') }}
      </p>

      <font-awesome-icon
        icon="circle-plus"
        class="mySchedule__noScheduleIcon"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import MyScheduleItem from '@/components/myschedule/MyScheduleItem';
import BaseButton from '@/components/BaseButton';
import capitalize from '@/filters/capitalize';
import { ModalBus } from '@/eventBus';

const MyScheduleSettings = markRaw(defineAsyncComponent({
  loader: () => import('@/components/myschedule/MyScheduleSettings' /* webpackChunkName: "myScheduleSettings" */),
}));

export default {
  components: {
    MyScheduleItem,
    BaseButton,
  },

  data() {
    return {
      canvas: null,
      context: null,
      interval: null,
      intervalTime: 10,
      accTime: 0,
    };
  },

  computed: {
    ...mapGetters('program', ['activePrograms']),
    ...mapState('schedule', ['schedule']),
    ...mapGetters('schedule', [
      'hasSchedule',
      'scheduleSettings',
    ]),

    numTotalWorkouts() {
      return this.schedule.length;
    },

    numCompletedWorkouts() {
      return this.schedule.filter(({ workout }) => workout.rating).length;
    },

    progressPercentage() {
      if (!this.numCompletedWorkouts) {
        return 0;
      }

      return this.numCompletedWorkouts * 100 / this.numTotalWorkouts;
    },

    bodyText() {
      const allWorkoutsCompleted = this.schedule.every(({ workout }) => workout.rating);

      if (allWorkoutsCompleted) {
        return this.$t('mySchedule.bodyAllWorkoutsCompleted');
      }

      const someWorkoutsCompleted = this.schedule.some(({ workout }) => workout.rating);

      if (someWorkoutsCompleted) {
        const numWorkoutsCompleted = this.schedule.filter(({ workout }) => workout.rating).length;
        const workoutIsToday = !!this.schedule.find(({ date, workout }) => moment(date).isSame(moment(), 'day') && !workout.rating);

        if (workoutIsToday) {
          return this.$t('mySchedule.bodySomeWorkoutsCompletedToday', {
            numWorkoutsCompleted,
          });
        }

        const nextWorkout = this.schedule.find(({ date, workout }) => moment(date).isAfter(moment(), 'day') && !workout.rating);
        const nextWorkoutDayName = moment(nextWorkout.date).format('dddd');
        const workoutIsTomorrow = moment(nextWorkout.date).diff(moment(), 'days') === 0;
        const day = capitalize(workoutIsTomorrow ? this.$t('common.tomorrowLabel') : nextWorkoutDayName);

        return this.$t('mySchedule.bodySomeWorkoutsCompleted', {
          numWorkoutsCompleted,
          day,
        });
      }

      const numSkippedWorkouts = this.schedule.filter(({ workout }) => !workout.rating).length;

      if (numSkippedWorkouts) {
        return this.$tc('mySchedule.bodySkippedWorkouts', numSkippedWorkouts, {
          numSkippedWorkouts,
        });
      }

      const workoutIsToday = !!this.schedule.find(({ date }) => moment(date).isSame(moment(), 'day'));

      if (workoutIsToday) {
        return this.$t('mySchedule.bodyUpcomingWorkoutsToday');
      }

      const nextWorkout = this.schedule.find(({ date }) => moment(date).isAfter(moment()));
      const nextWorkoutDayName = moment(nextWorkout.date).format('dddd');

      return this.$t('mySchedule.bodyUpcomingWorkouts', {
        day: nextWorkoutDayName,
      });
    },
  },

  watch: {
    activePrograms: {
      handler(activePrograms) {
        this.fetch(activePrograms);
      },
      deep: true,
    },

    hasSchedule(hasSchedule) {
      if (hasSchedule) {
        setTimeout(this.initProgressAnimation, 250);
      }
    },
  },

  mounted() {
    this.fetch(this.activePrograms);

    if (this.hasSchedule) {
      setTimeout(this.initProgressAnimation, 250);
    }
  },

  methods: {
    ...mapActions({
      fetch: 'schedule/fetch',
    }),

    onShowSettingsModal() {
      ModalBus.emit('open', {
        component: MyScheduleSettings,
        props: {
          settings: this.scheduleSettings,
        },
      });
    },

    initProgressAnimation() {
      const percentage = this.progressPercentage;

      this.canvas = this.$refs.canvas;

      if (this.canvas) {
        this.context = this.canvas.getContext('2d');
        this.interval = setInterval(this.progressInterval.bind(null, percentage), this.intervalTime);
      }
    },

    progressInterval(percentage) {
      this.accTime += this.intervalTime;

      const accPercentage = this.easeInOutSine(this.accTime, 0, percentage, 1000);
      this.drawProgressBar(accPercentage);

      if (accPercentage === percentage) {
        clearInterval(this.interval);
      }
    },

    drawProgressBar(percentage) {
      const angleOffset = -(Math.PI / 2);
      const startAngle = 0 + angleOffset;
      const endAngle = ((percentage * (2 * Math.PI)) / 100) + angleOffset;

      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

      this.context.beginPath();
      this.context.strokeStyle = 'rgba(255, 185, 43, 0.2)';
      this.context.lineWidth = 10;
      this.context.lineCap = 'butt';
      this.context.arc(this.canvas.width / 2, this.canvas.height / 2, (this.canvas.height / 2) - 5, startAngle, Math.PI * 1.5);
      this.context.stroke();

      this.context.beginPath();
      this.context.strokeStyle = '#ffb92b';
      this.context.lineWidth = 10;
      this.context.lineCap = 'butt';
      this.context.arc(this.canvas.width / 2, this.canvas.height / 2, (this.canvas.height / 2) - 5, startAngle, endAngle);
      this.context.stroke();
    },

    easeInOutSine(t, b, c, d) {
      // http://www.gizma.com/easing/
      // https://spicyyoghurt.com/tools/easing-functions
      return -c / 2 * (Math.cos(Math.PI * t / d) - 1) + b;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.mySchedule {
  padding: rem(16px);
  border: 2px dashed $color-beige--dark;
  border-radius: rem(6px);

  &--hasSchedule {
    background: $color-white;
    border: none;
  }

  @include desktop {
    border-radius: rem(10px);
  }
}

.mySchedule__noScheduleContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

.mySchedule__noScheduleBody {
  @include label--bold;
  margin: 0 0 rem(8px) 0;
  max-width: 120px;
  text-align: center;
  color: $color-green;

  @include desktop {
    margin: 0 0 rem(12px) 0;
  }
}

.mySchedule__noScheduleIcon {
  font-size: 24px;
  color: $color-green;
}

.mySchedule__header {
  margin: 0 0 12px 0;

  @include desktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.mySchedule__title {
  @include note;
  text-align: center;
  color: $color-green;

  @include desktop {
    @include label;
    text-align: left;
    color: $color-text;
  }
}

.mySchedule__editButton {
  @include label--bold;
  display: none;
  color: $color-green;
  cursor: pointer;

  @include desktop {
    display: block;
  }
}

.mySchedule__editButtonIcon {
  margin: 0 rem(6px) 0 0;
}

.mySchedule__content {
  display: flex;
  align-items: center;
  justify-content: center;

  @include desktop {
    justify-content: space-between;
  }
}

.mySchedule__progress {
  position: relative;
  flex: 0 0 80px;
  width: 80px;
  height: 80px;
}

.mySchedule__progressText {
  @include label;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: $color-grey;
}

.mySchedule__progressCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mySchedule__progressTextLarge {
  @include heading-3;
  color: $color-text;
}

.mySchedule__body {
  display: none;

  @include desktop {
    @include note;
    flex: 1 1 auto;
    display: block;
    margin: 0 0 0 rem(20px);
  }
}
</style>
