<template>
  <div class="medicalConsentBanner__container">
    <div class="medicalConsentBanner">
      <div class="medicalConsentBanner__content">
        <div class="medicalConsentBanner__title">
          {{ $t('medicalConsentBanner.title') }}
        </div>

        <p class="medicalConsentBanner__body">
          {{ $t('medicalConsentBanner.body') }}
        </p>
      </div>

      <div class="medicalConsentBanner__buttons">
        <base-button
          modifiers="primary"
          v-on:click="onPrimaryButtonClick"
        >
          {{ $t('medicalConsentBanner.primaryButtonLabel') }}
        </base-button>

        <base-button
          modifiers="link"
          v-on:click="onSecondaryButtonClick"
        >
          {{ $t('medicalConsentBanner.secondaryButtonLabel') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import { mapActions } from 'vuex';
import BaseButton from '@/components/BaseButton';
import { ModalBus } from '@/eventBus';

const MedicalConsentModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/MedicalConsentModal' /* webpackChunkName: "medicalConsentModal" */),
}));

export default {
  components: {
    BaseButton,
  },

  methods: {
    ...mapActions('member', ['updatePrivacySettings']),

    async onPrimaryButtonClick() {
      try {
        await this.updatePrivacySettings({ medical_consent: true });
      } catch(error) {
        //
      }
    },

    onSecondaryButtonClick() {
      ModalBus.emit('open', {
        component: MedicalConsentModal,
        props: {
          enableConsent: true,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.medicalConsentBanner__container {
  padding: 32px 20px 0 20px;
}

.medicalConsentBanner {
  padding: rem(16px) rem(24px);
  border-radius: 10px;
  background: $color-white;

  @include desktop {
    display: flex;
    align-items: center;
  }
}

.medicalConsentBanner__content {
  margin: 0 0 rem(32px) 0;

  @include desktop {
    flex: 1 1 auto;
    margin: 0 rem(32px) 0 0;
  }
}

.medicalConsentBanner__title {
  @include heading-3;
  margin: 0 0 rem(8px) 0;
}

.medicalConsentBanner__body {
  @include paragraph;
}

.medicalConsentBanner__buttons {
  flex: 0 0 auto;
}
</style>