<template>
  <div class="overviewRecipes">
    <base-heading type="4">
      {{ $t('index.recipesTitle') }}
    </base-heading>

    <mobile-slider>
      <template v-if="!recipes.data">
        <mobile-slider-item
          v-for="n in items"
          v-bind:key="n"
          mobile-width="75%"
          desktop-width="33.3%"
        >
          <skeleton-loader-card />
        </mobile-slider-item>
      </template>

      <template v-else>
        <mobile-slider-item
          v-for="(recipe, index) in recipeItems"
          v-bind:key="index"
          mobile-width="75%"
          desktop-width="33.3%"
        >
          <recipe-card
            v-bind:recipe="recipe"
            v-bind:in-slider="true"
          />
        </mobile-slider-item>
      </template>
    </mobile-slider>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import MobileSlider from '@/components/MobileSlider';
import MobileSliderItem from '@/components/MobileSliderItem';
import SkeletonLoaderCard from '@/components/SkeletonLoaderCard';
import BaseHeading from '@/components/BaseHeading';
import RecipeCard from '@/components/recipes/RecipeCard';

export default {
  components: {
    MobileSlider,
    MobileSliderItem,
    SkeletonLoaderCard,
    BaseHeading,
    RecipeCard,
  },

  data() {
    return {
      items: 3,
      recipes: [],
    };
  },

  computed: {
    recipeItems() {
      return this.recipes.data
        .slice(0)
        .sort(() => 0.5 - Math.random())
        .slice(0, this.items);
    },
  },

  async mounted() {
    this.recipes = await this.getRecipes();
  },

  methods: {
    ...mapActions({
      getRecipes: 'recipe/getAll',
    }),
  },
};
</script>
