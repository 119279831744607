<template>
  <router-link
    v-bind:to="{ name: 'programIntro', params: { programId: program.id } }"
    class="programCard"
  >
    <div
      v-if="program.image_vertical && program.name"
      class="programCard__item"
      v-bind:style="{ backgroundImage: `url(${program.image_vertical}?w=500&q=80)` }"
      v-bind:title="program.name"
    >
      <category-label
        v-bind:category="program.categories[0]"
        class="programCard__category"
      />

      <div class="programCard__content">
        <div
          v-if="program.name"
          class="programCard__title"
        >
          {{ program.name }}
        </div>

        <div
          v-if="program.trainers[0]"
          class="programCard__trainer"
        >
          {{ program.trainers[0].name }}
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import CategoryLabel from '@/components/CategoryLabel';

export default {
  components: {
    CategoryLabel,
  },

  props: {
    program: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.programCard {
  cursor: pointer;
}

.programCard__item {
  position: relative;
  width: 100%;
  height: $card-program-height;
  border-radius: $border-radius;
  background-color: $color-beige;
  background-size: cover;
  background-position: center center;
  overflow: hidden;

  @media (min-width: $breakpoint-md) {
    height: $card-program-height-desktop;
  }
}

.programCard__category {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.programCard__content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem;
  width: 100%;
  color: $color-white;
  background: linear-gradient(-180deg, rgba(0, 0, 0, 0) 1%, rgb(0, 0, 0) 97%);
}

.programCard__title {
  @include heading-5;
}

.programCard__trainer {
  @include label;
}
</style>
